module.exports = {
  apiUrl: process.env.REACT_APP_API_URL || "http://localhost:3000",
  appUrl: process.env.REACT_APP_APP_URL || "http://localhost:3001",
  gbifApiUrl: process.env.GBIF_API_URL || "https://api.gbif.org/v1/",
  fetchIntervals: {
    messages: 5000,
  },
  apiRoutes: {
    authenticate: "/v1/account/authenticate",
    getAccounts: "/v1/account",
    getAccount: "/v1/account",
    createAccount: "/v1/account",
    updateAccount: "/v1/account",
    deleteAccount: "/v1/account",
    getAccountPermissions: "/v1/account/get-permissions",
    resetPassword: "/v1/account/reset-password",
    linkAccountAndCustomer: "/v1/customers/link-account",
    unlinkAccountAndCustomer: "/v1/customers/unlink-account",
    getPlant: "/v1/plant",
    getPlants: "/v1/plant",
    createPlant: "/v1/plant",
    updatePlant: "/v1/plant",
    deletePlant: "/v1/plant",
    duplicatePlant: "/v1/plant/duplicate",
    getPlantSection: "/v1/plant-section",
    getPlantSections: "/v1/plant-section/list-by-type",
    createPlantSection: "/v1/plant-section",
    updatePlantSection: "/v1/plant-section",
    deletePlantSection: "/v1/plant-section",
    
    getContent: "/v1/content",
    getContents: "/v1/content",
    getContentsByType: "/v1/content/list-by-type",
    getContentTypes: "/v1/content/types",
    createContent: "/v1/content",
    updateContent: "/v1/content",
    deleteContent: "/v1/content",
    toggleContentCustomer: "/v1/content.toggle-customer",
    uploadImages: "/v1/media/upload-file",


    uploadCSV: "/v1/account/upload",

    deleteSectionImage: "/v1/plant-section/image",
    updateSectionImageInfo: "/v1/plant-section/image",


    deleteContentImage: "/v1/content/image",
    updateContentImageInfo: "/v1/content/image",

    getCustomer: "/v1/customers",
    getCustomers: "/v1/customers",
    createCustomer: "/v1/customers",
    updateCustomer: "/v1/customers",
    deleteCustomer: "/v1/customers",
    getPlantCustomers: "/v1/customers/list-by-plant",
    linkPlantToCustomer: "/v1/customers/link-plant",
    unlinkPlantFromCustomer: "/v1/customers/unlink-plant",
    getRegion: "/v1/region",
    getRegions: "/v1/region",
    createRegion: "/v1/region",
    updateRegion: "/v1/region",
    deleteRegion: "/v1/region",
    getPlantRegions: "/v1/region/by-plant",
    unlinkRegionAndPlant: "/v1/region/unlink-plant",
    linkRegionAndPlant: "/v1/region/link-plant",

    // quiz
    getQuiz: "/v1/quiz",
    getLeaderboard: "/v1/quiz/leaderboard",
    addOrUpdateQuiz: "/v1/quiz/upsert",
    resetQuiz: "/v1/quiz/reset",
    getQuizList: "/v1/quiz/list-by-customer",

    getQuizQuestion: "/v1/quiz-question",
    getQuizQuestions: "/v1/quiz-question",
    createQuizQuestion: "/v1/quiz-question",
    updateQuizQuestion: "/v1/quiz-question",
    deleteQuizQuestion: "/v1/quiz-question",
    duplicateQuizQuestion: "/v1/quiz-question/duplicate",
    deleteQuizQuestionImage: "/v1/quiz-question/image",
    updateQuizQuestionImageInfo: "/v1/quiz-question/image",
    linkQuizQuestionAndCustomer: "/v1/quiz-question/link-customer",
    unlinkQuizQuestionAndCustomer: "/v1/quiz-question/unlink-customer",
    toggleGame: "/v1/account/toggle-game",

    // quiz contents
    getQuizContent: "/v1/quiz-content",
    getQuizContents: "/v1/quiz-content",
    createQuizContent: "/v1/quiz-content",
    updateQuizContent: "/v1/quiz-content",
    deleteQuizContent: "/v1/quiz-content",

    deleteQuizContentImage: "/v1/quiz-content/image",
    updateQuizContentImageInfo: "/v1/quiz-content/image",

    linkUnlinkQuizContentCustomer: "/v1/quiz-content/link-unlink-customer",

    // answer
    getQuizAnswer: "/v1/quiz-answer",
    getQuizAnswers: "/v1/quiz-answer",
    getQuizQuestionAnswers: "/v1/quiz-answer",
    createQuizAnswer: "/v1/quiz-answer",
    updateQuizAnswer: "/v1/quiz-answer",
    deleteQuizAnswer: "/v1/quiz-answer",
    deleteQuizAnswerImage: "/v1/quiz-answer/image",
    updateQuizAnswerImageInfo: "/v1/quiz-answer/image",

    // question feedback
    getQuizQuestionFeedback: "/v1/quiz-question-feedback",
    createQuizQuestionFeedback: "/v1/quiz-question-feedback",
    updateQuizQuestionFeedback: "/v1/quiz-question-feedback",
    deleteQuizQuestionFeedback: "/v1/quiz-question-feedback",
    deleteQuizQuestionFeedbackImage: "/v1/quiz-question-feedback/delete-image",
    updateQuizQuestionFeedbackImageInfo:
      "/v1/quiz-question-feedback/update-image-info",

    // messages
    getConversations: "/v1/message/conversations",
    createMessage: "/v1/message",
    forwardMessage: "/v1/message/forward",

    readMessageThread: "/v1/message/read",
    
    notify: "/v1/message/notify",

    // not in use
    getMemberTokens: "/v1.deviceToken.getMemberTokens",


    // activity logs
    getActivityLog: "/v1/user-activity-log",

    // permissions
    getPermissions: "/v1/permission/list",
    togglePermission: "/v1/permission/toggle",
  },
  reactQuillModules: {
    toolbar: [
      [{ header: "1" }, { header: "3" }, { header: [3, 4, 5, 6] }],
      [{ size: [] }],
      ["bold", "italic", "undeline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  },
  reactQuillModulesHyperLink: {
    toolbar: [["link"], ["clean"]],
  },
  reactQuillFormats: [
    "header",
    "size",
    "bold",
    "italic",
    "undeline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
  ],
  accountRoleIdsByName: {
    admin: 1,
    member: 2,
    manager: 3,
  },
  accountRolesById: {
    1: "Admin",
    2: "Member",
    3: "Manager",
  },
};
